import React from 'react'
import { Link } from 'gatsby'
import { Layout, SEO } from '@/components'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Сторінку не знайдено" />
    <section className="not-found-section">
      <div className="not-found-container container">
        <h1 className="not-found-title">404: Сторінку не знайдено</h1>
        <h3 className="not-found-text">
          Можливо, ви помилилися адресою або сторінка перенесена
        </h3>
        <Link to="/" className="btn btn_gradient not-found-button">
          На головну
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
